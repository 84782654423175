import LoopSlider from "./LoopSlider";
import Swiper from "swiper";
import { Pagination, Autoplay, Navigation } from "swiper/modules";

let homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	modules: [Pagination, Autoplay],
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
});

let aboutProductSlider = new Swiper(".about-product-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.25,
	spaceBetween: 16,
	loop: true,
	navigation: {
		nextEl: ".about-product-slider .swiper-btn-next",
		prevEl: ".about-product-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 3.25,
			spaceBetween: 32,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 56,
		},
	},
});

let partnerSlider = new Swiper(".partner-slider .swiper", {
	modules: [Navigation, Autoplay],
	slidesPerView: 2,
	spaceBetween: 16,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	speed: 1000,
	navigation: {
		nextEl: ".partner-slider .swiper-btn-next",
		prevEl: ".partner-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 32,
		},
		1024: {
			slidesPerView: 6,
			spaceBetween: 32,
		},
	},
});

let certificationSlider = new Swiper(".certification-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.25,
	spaceBetween: 16,
	loop: true,
	navigation: {
		nextEl: ".certification-slider .swiper-btn-next",
		prevEl: ".certification-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 3.25,
			spaceBetween: 32,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 32,
		},
	},
});

let productCategorySlider = new Swiper(".product-category-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.25,
	spaceBetween: 16,
	loop: true,
	navigation: {
		nextEl: ".product-category-slider .swiper-btn-next",
		prevEl: ".product-category-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 3.25,
			spaceBetween: 32,
		},
		1024: {
			slidesPerView: 5,
			spaceBetween: 32,
		},
	},
});

let feedbackSlider = new Swiper(".feedback-slider .swiper", {
	modules: [Navigation, Pagination],
	slidesPerView: 1.25,
	spaceBetween: 16,
	navigation: {
		nextEl: ".feedback-slider .swiper-btn-next",
		prevEl: ".feedback-slider .swiper-btn-prev",
	},
	pagination: {
		el: ".feedback-slider .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 1.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 2.25,
			spaceBetween: 32,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

const productSlider = new LoopSlider(".product-slider", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2.5,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 3.5,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 4,
	},
});

const productSlider5 = new LoopSlider(".product-slider-5", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2.5,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 3.5,
	},
	1024: {
		spaceBetween: 20,
		slidesPerView: 5,
	},
});

const promoBannerSlider = new LoopSlider(
	".promo-banner-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 1,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 1,
		},
		1024: {
			spaceBetween: 20,
			slidesPerView: 1,
		},
	},
	{
		slidesPerView: 1,
	}
);
