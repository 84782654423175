const clickMapResult = () => {
	const iframe = $("#dealerMap");
	$(".dealer-item").each(function () {
		let _this = $(this);
		let src = _this.data("map");
		$(_this).on("click", function () {
			$(".dealer-item").not(_this).removeClass("active");
			_this.addClass("active");
			iframe.attr("src", src);
		});
	});
	$(".dealer-item").eq(0).trigger("click");
};

clickMapResult();
