$(".product-sidenav .has-submenu .toggle").on("click", function (e) {
	e.preventDefault(); // Prevent default action if toggle is a link or a button
	e.stopPropagation(); // Stop propagation to handle nested menus correctly

	var $this = $(this);
	var $submenu = $this.siblings(".submenu"); // Select the sibling submenu
	var $hasSubmenu = $this.closest(".has-submenu"); // Get the closest .has-submenu parent

	$submenu.slideToggle();
	$hasSubmenu.toggleClass("active");
});

$(".toggle-filter-mobile").on("click", function () {
	$(".product-filter-wrap").toggleClass("show");
	$("body").toggleClass("overflow-hidden");
	$(".filter-backdrop").fadeToggle();
});

$(".filter-backdrop").on("click", function () {
	$(".product-filter-wrap").toggleClass("show");
	$("body").toggleClass("overflow-hidden");
	$(".filter-backdrop").fadeToggle();
});
