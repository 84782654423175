$(function () {
	if ($(window).width() > 1024.98) {
		$(".product-category-menu-cate .sub-cate").innerWidth(
			$(".home-banner").innerWidth()
		);
		$(window).resize(function () {
			$(".product-category-menu-cate .sub-cate").innerWidth(
				$(".home-banner").innerWidth()
			);
		});
	}

	$(".product-category-menu-list .has-cate").each(function () {
		let id = $(this).attr("data-id");
		let toggle = $(this).find(".toggle");
		let $subCate = $(
			".product-category-menu-cate .sub-cate[data-id=" + id + "]"
		);
		let timeout;
		if ($(window).width() > 1024.98) {
			$(this).hover(
				function () {
					clearTimeout(timeout);
					$subCate.addClass("show");
				},
				function () {
					timeout = setTimeout(function () {
						$subCate.removeClass("show");
					}, 300); // Delay in milliseconds
				}
			);

			$subCate.hover(
				function () {
					clearTimeout(timeout);
				},
				function () {
					timeout = setTimeout(function () {
						$subCate.removeClass("show");
					}, 50);
				}
			);
		} else {
			$subCate.appendTo($(this));
			toggle.on("click", function () {
				toggle.toggleClass("fa-rotate-90");
				$subCate.slideToggle();
			});
		}
	});
});
