$(".countdown").each(function () {
	var date = $(this).data("countdown");
	var formatDate = parseDate(date);
	$(this).countdown(formatDate, function (event) {
		$(this).html(
			event.strftime(`<span>%D</span>:<span>%H</span>:<span>%M</span>`)
		);
	});
});

function parseDate(date) {
	var dateInit = date;
	var datearray = dateInit.split(" ");
	var dateNeedFormat = datearray[0];
	var dateNeedFormatArray = dateNeedFormat.split(/\//);
	var dateAfterFormat = [
		dateNeedFormatArray[1],
		dateNeedFormatArray[0],
		dateNeedFormatArray[2],
	].join("/");
	return [dateAfterFormat, datearray[1]].join(" ");
}
