import { Fancybox } from "@fancyapps/ui";
import "./modules/Header";
import "./modules/Cart";
import "./modules/Slider";
import "./modules/Accordion";
import "./modules/ExpandContent";
import "./modules/BackToTop";
import "./modules/Product";
import "./modules/ProductCate";
import "./modules/ProductDetail";
import "./modules/PriceFilter";
import "./modules/Rating";
import "./modules/CountDown";
import "./modules/Dealer";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

Fancybox.bind("[data-fancybox]", {
	// Your custom options
});

$(function () {});
