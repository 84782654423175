import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";
const productDetailSlider = () => {
	const productDetailThumbnailSlider = new Swiper(
		".product-detail-thumbnail .swiper",
		{
			spaceBetween: 16,
			slidesPerView: 2.5,
			observer: true,
			observeParents: true,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				768: {
					slidesPerView: 3.5,
				},
				1024: {
					slidesPerView: 4,
				},
			},
		}
	);
	const productDetailImageSlider = new Swiper(
		".product-detail-images .swiper",
		{
			modules: [Navigation, Thumbs],
			observer: true,
			observeParents: true,
			thumbs: {
				swiper: productDetailThumbnailSlider,
			},
			navigation: {
				nextEl: ".product-detail-images .swiper-btn-next",
				prevEl: ".product-detail-images .swiper-btn-prev",
			},
			on: {
				slideChange: function () {
					let activeIndex = this.activeIndex + 1;

					let nextSlide = $(
						`.product-detail-thumbnail .swiper-slide:nth-child(${
							activeIndex + 1
						})`
					);
					let prevSlide = $(
						`.product-detail-thumbnail .swiper-slide:nth-child(${
							activeIndex - 1
						})`
					);

					if (
						nextSlide &&
						!nextSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slideNext();
					} else if (
						prevSlide &&
						!prevSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slidePrev();
					}
				},
			},
		}
	);
};

productDetailSlider();

if ($(".product-detail-comment").length > 0) {
	$(".product-detail-comment").append($(".commentpanel"));
}
